@tailwind base;
@tailwind components;
@tailwind utilities;



body {
    margin: 0;
    /* @apply font-inter; */
    color: #333;
    background-color: #FAFBFF;
}
* {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    outline: none;
}

input ,textarea {
    font-family:'Inter', 'sans-serif' ;
}




/* p {
    color: #4F4F4F;
} */
.forgot-password {
    color:#161941;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .notification-tab > .active {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(59 130 246 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .notification-tab > .active:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .order-tab > .active {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .order-tab > .active:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

 

  .before\:mr-3::before {
    content: var(--tw-content);
    margin-right: 0.75rem;
  }

  .before\:h-1::before {
    content: var(--tw-content);
    height: 0.25rem;
  }

  .before\:w-1::before {
    content: var(--tw-content);
    width: 0.25rem;
  }

  .before\:rounded-full::before {
    content: var(--tw-content);
    border-radius: 9999px;
  }

  .before\:bg-gray-300::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(209 213 219 / var(--tw-bg-opacity));
  }

  .hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  }

  .hover\:bg-gray-950:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity));
  }

  .hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity));
  }

  .hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity));
  }

  .hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }

  .focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
  }

  .group:hover .group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
  }

  .group.selected .group-\[\.selected\]\:block {
    display: block;
  }

  .group.selected .group-\[\.selected\]\:rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }

  .group.active .group-\[\.active\]\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .group.selected .group-\[\.selected\]\:bg-gray-950 {
    --tw-bg-opacity: 1;
    background-color: rgb(3 7 18 / var(--tw-bg-opacity));
  }

  .group.active .group-\[\.active\]\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .group.selected .group-\[\.selected\]\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity));
  }

  .upload_loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 50%;
    animation: spin 1s linear infinite;
  }

  .error_text{
    font-size: larger;
    font-weight: 600;
    color: #2d2d41;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .shimmer {
    background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
    background-size: 300%;
    animation: shimmer 1s infinite linear;
}
.initial {
  background: #e3e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes shimmer {
    0% {
        background-position-x: 100%;
    }
    100% {
        background-position-x: 0%;
    }
}

.button-custom {
  position: absolute;
  right: 15px;
  top: 10px;
  height: 2.5rem; /* or h-10 */
  padding: 0 1.25rem; /* or px-5 */
  background-color: #161941;
  color: white;
  font-size: 0.875rem; /* or text-sm */
  font-weight: 600; /* or sm:font-semibold */
  border-radius: 10px;
}

