@keyframes ldio-pfhxqnipyzk {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldioLoader div {
  position: absolute;
  width: 50px;
  height: 50px;
  border: 6px solid #161941;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldioLoader div {
  animation: ldio-pfhxqnipyzk 1s linear infinite;
  top: 50%;
  left: 50%;
}
.loadingioSpinnerRolling {
  width: 80px;
  height: 80px;
  display: inline-block;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
}
.ldioLoader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.minContentWidth {
  width: min-content !important;
  margin: 0 auto;
  border-radius: 99999px !important;
  background-color: transparent !important;
  border: none !important;
}
