
@media (min-width: 768px) {
    .md\:ml-64 {
      margin-left: 16rem;
    }

    .md\:hidden {
      display: none;
    }

    .md\:w-\[calc\(100\%-256px\)\] {
      width: calc(100% - 256px);
    }

    .main.active {
        margin-left: 0px;
        width: 100%;
      }
  }



  @media screen and (max-width:1023px) {
    .commonpage.active-sidebar .main-content {
      width: 100%;
      margin-left: 0;
    }
  }

  @media screen and (max-width:575px) {
    .dropline::before {
      height: 4px;
    }
    .error_text {
      justify-content: left;
      padding: 40px 90px;
    }
    .error_text img {
      width: 150px;
    }
    input {
      border-width: 1px;
    }
    .css-1atrtk7-control, .css-1u11f2k-control {
      height: 42px !important;
      min-height: 42px !important;
    }
    .button-custom {
      position: static;
      /* margin: 20px; */
    }
  }